import Image from 'next/image';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { MainBannerItemProps } from './constants';
import { event } from 'nextjs-google-analytics';
import Link from 'next/link';
import { NextRouter } from 'next/router';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


interface MainBannerProps {
  main_banner: Array<MainBannerItemProps>;
  router: NextRouter;
}

const MainBanner = (props: MainBannerProps) => {
  const { main_banner } = props;

  const handleBannerClick = (link: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    event("banner_click", {
      event_category: "Homepage-MainBanner",
      event_label: link,
    })

    props.router.push(link);
  }

  return (
    <div className="mb-12">
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        loop={true}
        slidesPerView={1}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        speed={1200}
        pagination={{
          clickable: true,
          enabled: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mainBanner"
      >
        {main_banner.map((banner, index) => (
          <SwiperSlide key={index} className="relative">
            <Link href={banner.link !== null && banner.link.length > 0 ? banner.link : '#'} onClick={handleBannerClick(banner.link !== null && banner.link.length > 0 ? banner.link : '#')}>
              <div className="md:hidden">
                <Image
                  src={banner.mobile_image}
                  alt={banner.image_alt === null || banner.image_alt === undefined || (banner.image_alt !== null && banner.image_alt.length === 0) ? "Main Banner" : banner.image_alt}
                  quality={100}
                  width={1080}
                  height={1920}
                  className="w-full"
                />
              </div>
              <div className="hidden md:block">
                <Image
                  src={banner.desktop_image}
                  alt={banner.image_alt === null || banner.image_alt === undefined || (banner.image_alt !== null && banner.image_alt.length === 0) ? "Main Banner" : banner.image_alt}
                  quality={100}
                  width={2880}
                  height={1440}
                  className="w-full"
                />
              </div>
              {banner.text !== null && (<div className="absolute w-full bottom-20 md:-translate-y-1/2 md:top-1/2">
                <div className="container">
                  <div className="md:max-w-[500px] text-white">
                    <h1 className="mb-2 text-[32px] leading-[40px] font-bold md:text-[64px] md:leading-[70px] font-poppins -tracking-[1.6px] md:-tracking-[3.2px]">{banner.text}</h1>
                  </div>
                </div>
              </div>)}
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default MainBanner
