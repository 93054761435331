import Link from "next/link"
import Image from "next/image"
import { FlashSaleItemProps } from "./constants"
import Countdown from "react-countdown"
import OurPick from "../Utils/OurPick"
import { CustomVariantItemProps, MinProductItemProps } from "../Product/constants"


interface FlashSaleProps {
  flash_sale: FlashSaleItemProps;
  setSelectProduct: (product: MinProductItemProps) => void;
  selectedCustomVariant: CustomVariantItemProps | null;
  setSelectedCustomVariant: (variant: CustomVariantItemProps | null) => void;
  fromName: string;
  setFromName: (value: string) => void;
  toName: string;
  setToName: (value: string) => void;
  toEmail: string;
  setToEmail: (value: string) => void;
  toMobile: string;
  setToMobile: (value: string) => void;
  toMessage: string;
  setToMessage: (value: string) => void;
  pregnancy: string;
  setPregnancy: (value: string) => void;
  duDate: Date | null;
  setDuDate: (value: Date | null) => void;
  childName: string;
  setChildName: (value: string) => void;
  childAge: number | null;
  setChildAge: (value: number | null) => void;
  childGender: string;
  setChildGender: (value: string) => void;
  setShowFlashSale: (value: boolean) => void;
}

const FlashSale = (props: FlashSaleProps) => {

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      props.setShowFlashSale(false);
      return <p className="text-sm text-white">Selesai</p>
    } else {
      return (
        <div className="flex items-center justify-center space-x-4">
          <p className="text-xs md:text-base font-medium md:font-normal text-white">Berakhir Dalam</p>
          <div className="flex items-center space-x-1">
            <p className="bg-white rounded min-w-[28px] md:min-w-[40px] text-center text-mooimom font-bold text-base md:text-xl">{hours}</p>
            <p className="text-2xl tracking-[-8.5%] text-white">:</p>
            <p className="bg-white rounded min-w-[28px] md:min-w-[40px] text-center text-mooimom font-bold text-base md:text-xl">{minutes}</p>
            <p className="text-2xl tracking-[-8.5%] text-white">:</p>
            <p className="bg-white rounded min-w-[28px] md:min-w-[40px] text-center text-mooimom font-bold text-base md:text-xl">{seconds}</p>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="w-full font-poppins bg-mooimom my-12">
      <div className="w-full bg-notificationText">
        <div className="container flex items-center justify-center py-4 md:py-2 md:justify-between">
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/flash_sale.png"}
            alt="flash sale logo"
            quality={100}
            width={600}
            height={184}
            className="w-[200px] hidden md:block"
          />
          <Countdown date={props.flash_sale.end_time} renderer={renderer} />
          <Link href={`/product-category/all-promo?flash_sale=${props.flash_sale.id}`} className="hidden md:block">
            <p className="text-xs text-white underline">Lihat Semua</p>
          </Link>
        </div>
      </div>
      <div className="pt-4 pb-8 md:container md:py-8">
        <div className="flex items-center justify-between px-6 mb-4 md:hidden">
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/flash_sale.png"}
            alt="flash sale logo"
            quality={100}
            width={600}
            height={184}
            className="w-[117px] md:hidden"
          />
          <Link href={`/product-category/all-promo?flash_sale=${props.flash_sale.id}`}>
            <p className="text-xs text-white underline">Lihat Semua</p>
          </Link>
        </div>
        <OurPick
          tittle=""
          link=""
          image=""
          banner_list={[]}
          product_list={props.flash_sale.product_list}
          attrId={null}
          customName={null}
          expandColor={false}
          showBorder={false}
          customMarginTop="mt-0"
          customMarginBottom="mb-0"
          setSelectProduct={props.setSelectProduct}
          selectedCustomVariant={props.selectedCustomVariant}
          setSelectedCustomVariant={props.setSelectedCustomVariant}
          fromName={props.fromName}
          setFromName={props.setFromName}
          toName={props.toName}
          setToName={props.setToName}
          toEmail={props.toEmail}
          setToEmail={props.setToEmail}
          toMobile={props.toMobile}
          setToMobile={props.setToMobile}
          toMessage={props.toMessage}
          setToMessage={props.setToMessage}
          pregnancy={props.pregnancy}
          setPregnancy={props.setPregnancy}
          duDate={props.duDate}
          setDuDate={props.setDuDate}
          childName={props.childName}
          setChildName={props.setChildName}
          childAge={props.childAge}
          setChildAge={props.setChildAge}
          childGender={props.childGender}
          setChildGender={props.setChildGender}
          pwp_name={null}
          pwp={[]}
        />
      </div>
    </div>
  )
}

export default FlashSale
